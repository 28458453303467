import React from 'react';

class Submission extends React.Component {
  render() {
    return (
      <h4> Submission </h4>
    )
  }
}

let wrapped = Submission;
export default wrapped;
